// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("./../src/pages/articles/index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-project-tags-jsx": () => import("./../src/pages/project_tags.jsx" /* webpackChunkName: "component---src-pages-project-tags-jsx" */),
  "component---src-pages-projects-categories-jsx": () => import("./../src/pages/projects/categories.jsx" /* webpackChunkName: "component---src-pages-projects-categories-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-project-template-jsx": () => import("./../src/templates/project-template.jsx" /* webpackChunkName: "component---src-templates-project-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../src/templates/tag-template.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */)
}

